import React from 'react'
import './index.scss'
function TopWord (props) {
  return (
    <div className='TopWord'>
      <img src={props.mainImg} alt="" />
    </div>
  )
}

export default TopWord
